<template>
  <section class="our-products">
    <div class="container">
        <div class="section-heading">
            بعض من أعمالنا
        </div>
        <div class="products-con">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/quizat-preview.png" alt="">
                            <div class="label app-label">
                                تطبيقات
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="https://play.google.com/store/apps/details?id=com.Quizat.www&hl=en&gl=US" target="_blank">
                                Quizat: Single & Multi Trivia Game
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/echo-preview.jpg" alt="">
                            <div class="label e-label">
                                متاجر
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="https://echoemaar.com/" target="_blank">
                                متجر صدى الاعمار
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/dabaan-preview.png" alt="">
                            <div class="label website-label">
                                مواقع
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="https://aldabaan.sa/" target="_blank">
                                منصة شركة الضبعان العقارية
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/taghtia-preview.jpg" alt="">
                            <div class="label website-label">
                                مواقع
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="https://taghtiauctions.sa/" target="_blank">
                                منصة تغطية للمزادات
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/aqar-preview.jpg" alt="">
                            <div class="label website-label">
                                مواقع
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="https://aqar-alarabia.sa/" target="_blank">
                                عقار العربية
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/designs/nileClub.png" alt="">
                            <div class="label design-label">
                                تصاميم
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="#" target="_blank">
                                شعار نادي النيل
                            </a>
                        </div>
                    </div>
                </div>


                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/designs/emaar.jpg" alt="">
                            <div class="label design-label">
                                تصاميم
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="#" target="_blank">
                                اعمار
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/designs/emaar2.jpg" alt="">
                            <div class="label design-label">
                                تصاميم
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="#" target="_blank">
                                تصميم مواقع تواصل
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/designs/easy.jpg" alt="">
                            <div class="label design-label">
                                تصاميم
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="#" target="_blank">
                                ايزي لايف
                            </a>
                        </div>
                    </div>
                </div>


                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="product-review-con">
                        <div class="product-review-img">
                            <img src="@/assets/designs/SM1.png" alt="">
                            <div class="label design-label">
                                تصاميم
                            </div>
                        </div>
                        <div class="product-review-text">
                            <a href="#" target="_blank">
                                تصميم مواقع تواصل
                            </a>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.section-heading {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    margin-top: 20px;
}
.col-4 {
    
}
.product-review-con {
    padding: 10px;
 .product-review-img {
    position:realtive;
    height: 200px;
    border: 1px solid $primary;

    img {
        width: 100%;
        height: 100%;
    }
    .label {
        position: absolute;
        right: 10px;
        top: 20px;
        color:white;
        padding: 4px 10px;
        border-radius: 4px 0px 0px 4px;
    }

    .app-label {
        background: $link;

    }
    .e-label {
        background: $primaryDark;

    }
    .website-label {
        background: $accent;
    }
    .design-label {
        background: $sideBarHover;
    }
 }   
 .product-review-text {
    text-align: center;

    a {
        color:black;
        font-weight: bold;
        text-align: center;
        font-size:18px
    }
 }
}
</style>