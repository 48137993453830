<template>
  <section class="product">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="left-side">
            <img src="@/assets/product.png" alt="" />
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="right-side">
            <div class="heading">نقدم الحلول المتكاملة للشركات والافراد</div>
            <div class="text">
              متخصصون في تصميم المتاجر الإلكترونية و تصميم المواقع وتطبيقات الويب. نحن نقدم حلولًا مبتكرة ومخصصة تلبي احتياجات عملك عبر الإنترنت.
              متخصصون في تصميم المتاجر الإلكترونية و تصميم المواقع وتطبيقات الويب. نحن نقدم حلولًا مبتكرة ومخصصة تلبي احتياجات عملك عبر الإنترنت.

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.product {
  margin-top: 100px;
  .row {
    align-items: center;
  }
  .left-side {
    border-radius: 10px;
    img {
        overflow:hidden;
        width: 100%;
    }
  }
  .right-side {
    padding: 10px;
    .heading {
        font-size: 40px;
        font-weight: bold
    }
    .text {
        margin-top: 15px;
        color: #565656;
    }
  }
}
</style>
