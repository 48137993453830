<template>
  <section class="services">
    <div class="r-24">
        <img src="@/assets/rectangle-24.png" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="text-side">

                <div class="heading">كيف يمكننا مساعدتك؟</div>
                <!-- <div class="text">
                    We build readymade websites, mobile applications, and elaborate
                    online business services.
                </div> -->
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 col-12">
            <div class="cards">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12 left-side">
                        <div class="card first">
                            <div class="card-img">
                                <img src="@/assets/box-search.png" alt="">
                            </div>
                            <div class="card-heading">
                                تصميم المتاجر
                            </div>
                            <div class="card-text">
                                تصميم متجر إلكتروني يجمع بين الإبداع والأداء. قدم منتجاتك وخدماتك بأفضل شكل واجعل تجربة العميل فريدة من نوعها

                            </div>
                        </div>
                        <div class="card second">
                            <div class="card-img">
                                <img src="@/assets/code.png" alt="">
                            </div>
                            <div class="card-heading">
                                برمجة التطبيقات
                            </div>
                            <div class="card-text">
                                برمجة التطبيقات بإحترافية فائقة وخالية من الأخطاء، لتحقيق فكرتك مهما كانت، مع تجربة مستخدم سلسة وسهلة تجعل تجربتة مميزة لعملائك وتبرز تطبيقك

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12 right-side">
                        <div class="card third">
                            <div class="card-img">
                                <img src="@/assets/empty-wallet.png" alt="">
                            </div>
                            <div class="card-heading">
                                برمجة المواقع 
                            </div>
                            <div class="card-text">
                                تصميم موقعك الإلكتروني بأعلى درجات الاحتراف والإبداع، حيث نسعى جاهدين لإبراز هويتك بشكل جذاب وفريد، مما يضمن لك وجوداً استثنائياً على الإنترنت

                            </div>
                        </div>
                        <div class="card fourth">
                            <div class="card-img">
                                <img src="@/assets/chart-square.png" alt="">
                            </div>
                            <div class="card-heading">
                                تصميم الهويات
                            </div>
                            <div class="card-text">
                                برمجة التطبيقات بإحترافية فائقة وخالية من الأخطاء، لتحقيق فكرتك مهما كانت، مع تجربة مستخدم سلسة وسهلة تجعل تجربتة مميزة لعملائك وتبرز تطبيقك

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.services {
    margin-top: 100px;
    position: relative;
    .row {
        align-items: center;
    }
}
.r-24 {
    position: absolute;
    top: 0;
    right: 0;
    img {
        width: 100%;
    }
}
.text-side {
    .heading {
        font-size: 40px;
        font-weight: bold;
    }
    .text {
        color: #565656;
    }
}
.cards {
    .row {
        justify-content: space-between;
    }
    .left-side {
        margin-top: 50px;
    }
}
.card {
    margin-top: 20px;
    border: 1px solid #F2F2F2;
    background-color: white;
    border-radius: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0px;
    .card-img {
        padding: 15px;
        border-radius: 20px;
        img {
            width: 75px;
            height: 75px;
        }
    }
    .card-heading {
        width: 50%;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-top: 30px;
    }
    .card-text {
        width: 70%;
        margin-top: 30px;
        text-align: center;
        color: #757575;
    }
}
.first {
    .card-img {
        background-color: #F1F7FF;
        
    }
}
.second {
    .card-img {
        background-color: #FFF2F8;
        
    }
}
.third {
    .card-img {
        background-color: #FFF7E3;
        
    }
}
.fourth {
    .card-img {
        background-color: #DEFFEE;
        
    }
}
</style>
