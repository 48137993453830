<template>
  <section class="our-clients">
    <div class="container">
      <div class="our-clients-container">
        <div class="left-side">
          <div class="heading">Our Clients</div>
          <div class="text">
            Several selected clients, who already believe in our service.
          </div>
        </div>
        <div class="right-side">
          <!-- <img src="@/assets/google.png" alt="" />
          <img src="@/assets/airbnb.png" alt="" />
          <img src="@/assets/uber.png" alt="" />
          <img src="@/assets/amazon.png" alt="" /> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.our-clients {
    margin-top: 100px;
}
.our-clients-container {
    display: flex;
    align-items: center;
}
.left-side {
    display: flex;
    flex-direction: column;
  .heading {
    font-size: 40px;
    font-weight: bold;
  }
  .text {
    color: #757575;
  }
}
.right-side {
    width: 100%;
    display: flex;
    justify-content: space-between;
    img {
        width: 150px;
        height: 50px;
    }
    
}
</style>
