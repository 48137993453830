<template>
  <section class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="logo">Neon Tech</div>
          <div class="text">
            Leading digital agency with solid design and development expertise.
            We build readymade websites, mobile applications, and elaborate
            online business services.
            <div class="phone">WhatsApp: +966562992873</div>
          </div>
          <div class="social">
            <router-link to="">
              <img class="first" src="@/assets/facebook.png" alt="" />
            </router-link>
            <router-link to="">
              <img src="@/assets/twitter.png" alt="" />
            </router-link>
            <router-link to="">
              <img src="@/assets/linkedin.png" alt="" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="footer-divider"></div>
      <div class="footer-copyrights">Copyright © 2023 - Neon Tech</div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer {
  margin-top: 30px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}
.logo {
  text-align: center;
}
.text {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}
.phone {
  text-align: center;
}
.social {
  text-align: center;
  img {
    padding: 5px;
  }
}
.footer-divider {
  margin-top: 20px;
  background-color: #f1f1f1;
  width: 100%;
  height: 2px;
}
.footer-copyrights {
  margin-top: 15px;
  color: #a4a4a4;
  font-size: 14px;
  text-align: center;
}
</style>
