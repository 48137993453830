<template>
  <section class="hero">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="left-side">
          <div class="heading">
            أحلامك <span class="primary">الرقمية</span> <br>
            <span class="accent">بأناقة</span> وإبداع
          </div>
          <div class="text">
            متخصصون في تصميم المتاجر الإلكترونية وتصميم المواقع وتطبيقات الويب نحن نقدم حلولاً مبتكرة ومخصصة تلبي احتياجات عملك عبر الإنترنت
          </div>
          <div class="btn">
            <button class="primary" >اتصل بنا</button>
            <button class="accent"> استشارة مجانية</button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="right-side">
          <img src="@/assets/hero.gif" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">

.row {
  align-items: center;
}
.left-side {
    padding: 0 100px;
  .heading {
    margin-top: 20px;
    font-size: 40px;
    font-weight: bold;
    .primary {
      color: $primary;
    }
    .accent {
      color: $accent;
    }
  }
  .text {
    margin-top: 20px;
    color: #565656;
  }
  .btn {
    margin-top: 20px;

    button {
      color: white;
      font-weight: bold;
      border: none;
      padding: 8px 16px;
      border-radius: 8px;

    }
    .primary {
      background: $primary;
      margin-left: 5px;
      margin-bottom: 5px;
    }
    .accent {
      background: $accent;
    }
  }
}
.right-side {
  padding: 10px;
    img {
        width: 100%;
    }
}
</style>
