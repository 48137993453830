<template>
  <div class="home">
    <NavBar />
    <Hero />
    <!-- <Clients /> -->
    <Services/>
    <Products />
    <Product />
    <Footer/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Hero from '@/components/Hero.vue'
import Clients from '@/components/Clients.vue'
import Services from '@/components/Services.vue'
import Products from '@/components/Products.vue'
import Product from '@/components/Product.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    NavBar,
    Hero,
    Clients,
    Services,
    Product,
    Products,
    Footer
  }
}
</script>
